<template>
  <div id="app">
    <nav
      id="nav"
      class="nav-top flex flex-col md:flex-row justify-center items-center md:justify-between sticky top-0 bg-white"
    >
      <div class="p-3 md:ml-10">
        <img src="./assets/logo.png" class="object-contain h-12 md:h-8" />
      </div>
      <div
        class="mx-3 md:mr-10 w-full md:w-6/12 flex justify-around md:justify-end"
      >
        <div class="mx-3"><a href="#home">Home</a></div>
        <div class="mx-3"><a href="#about">About</a></div>
        <div class="mx-3"><a href="#projects">Projects</a></div>
        <div class="mx-3"><a href="#contact">Contact</a></div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.changeNavColor);
  },
  unmounted() {
    window.removeEventListener("scroll", this.changeNavColor);
  },
  methods: {
    changeNavColor() {
      const nav = document.getElementById("nav");
      if (window.scrollY === 0) {
        nav.classList.remove("nav-scrolled");
        nav.classList.add("nav-top");
      } else {
        nav.classList.remove("nav-top");
        nav.classList.add("nav-scrolled");
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: var(--navPadding);
  opacity: var(--navOpacity);
}

.nav-top {
  --navPadding: 20px;
  --navOpacity: 100%;
}

.nav-scrolled {
  --navPadding: 0px;
  --navOpacity: 80%;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
</style>
