<template>
  <div>
    <!-- home -->
    <div class="slider flex flex-col justify-center items-center" id="#home">
      <div class="w-8/12 flex justify-center">
        <img src="../assets/logo.png" class="object-contain" />
      </div>
      <div class="m-4 text-center text-gray-700">
        We build high-quality systems to help businesses manage their operations
        smoothly & grow their sales.
      </div>
      <a
        class="bg-blue-700 rounded-lg text-white p-2 m-3 hover:bg-blue-900 cursor-pointer"
        href="#about"
      >
        Learn more
      </a>
      <a
        class="text-xs text-gray-300 justify-self-end"
        href="https://www.freepik.com/vectors/frame"
        >Frame vector created by rawpixel.com - www.freepik.com</a
      >
    </div>

    <!-- about us -->
    <div class="p-3 md:p-10 grid grid-cols-1 md:grid-cols-2" id="about">
      <p class="col-span-1 md:col-span-2 text-xl text-center m-1 md:mb-8">
        <b>About Us</b>
      </p>
      <div class="flex flex-col col-span-1 bg-white p-3 rounded-lg m-2">
        <div class="h-24 w-24 p-3 self-center m-1 md:m-3">
          <img
            src="../assets/story.png"
            alt=""
            class="align-self-center object-contain"
          />
        </div>
        <p class="text-xl text-center m-1 md:m-3"><b>Our Story</b></p>
        <p class="m-1 md:m-3 text-gray-700">
          After working at Metro by T-Mobile for over 5
          years and managing over 25 stores with hundreds of employees, Yousif
          obtained extremely valuable experience in business operations, sales,
          human resources, and the software systems that manage those
          operations. He grew an interest in helping businesses in the Middle
          East region, especially his home country Iraq, to grow their sales and
          adopt a systemized way of managing thier retail stores using the
          American way of doing business that he learned through his experience.
          To his surprise, he found that the Iraqi market, unlike the U.S.
          market, lacks reliable & modern business management software systems.
          Hence, he decided to start a software company to build reliable and
          efficient business management systems for the Iraqi market.
        </p>
      </div>

      <div class="col-span-1 ">
        <div class="flex flex-col bg-white p-3 rounded-lg m-2">
          <div class=" h-24 w-24 p-3 self-center m-1 md:m-3">
            <img
              src="../assets/company-vision.png"
              alt=""
              class="align-self-center object-contain"
            />
          </div>
          <p class="text-xl text-center m-1 md:m-3"><b>Our Vision</b></p>
          <p class="m-1 md:m-3 text-gray-700">
            Help local economies grow exponentially by empowering the local
            business industry.
          </p>
        </div>
        <div class="flex flex-col bg-white p-3 rounded-lg m-2">
          <div class=" h-24 w-24 p-3 self-center m-1 md:m-3">
            <img
              src="../assets/target.png"
              alt=""
              class="align-self-center object-contain"
            />
          </div>
          <p class="text-xl text-center m-1 md:m-3"><b>Our Mission</b></p>
          <p class="m-1 md:m-3 text-gray-700">
            Our mission is to help businesses grow their sales and manage their
            operations smoothly by building reliable and modern business
            management software systems using world-class methods and tooling.
          </p>
        </div>
      </div>

      <div class="col-span-1 bg-white md:col-span-2 rounded-lg m-2 p-3">
        <p class="col-span-1 md:col-span-2 text-xl text-center m-1 md:m-3">
          <b>Our Team</b>
        </p>
        <div class="flex justify-center w-full flex-wrap">
          <div class="p-3 m-3 rounded-lg flex flex-col items-center">
            <img
              src="../assets/yousif_.png"
              alt="profile-pic"
              class="h-32 object-contain rounded-full"
            />
            <p class="mt-3 mx-3"><b>Yousif Zain</b></p>
            <p class="text-sm">Founder & CEO</p>
            <div class="inline-block">
              <a
                class="cursor-pointer hover:text-blue-700 m-1"
                href="mailto:yousif@goclassyllc.com"
              >
                <font-awesome-icon icon="envelope"></font-awesome-icon>
              </a>
            </div>
          </div>

          <div class="p-3 m-3 rounded-lg flex flex-col items-center">
            <img
              src="../assets/ahmed.png"
              alt="profile-pic"
              class="h-32 object-contain rounded-full"
            />
            <p class="mt-3 mx-3"><b>Ahmed Salem</b></p>
            <p class="text-sm">Backend Software Engineer</p>
            <div class="inline-block">
              <a
                class="cursor-pointer hover:text-blue-700 m-1"
                href="mailto:keepgoing.ahmed@gmail.com"
              >
                <font-awesome-icon icon="envelope"></font-awesome-icon>
              </a>
            </div>
          </div>

          <div class="p-3 m-3 rounded-lg flex flex-col items-center">
            <img
              src="../assets/hussien.png"
              alt="profile-pic"
              class="h-32 object-contain rounded-full"
            />
            <p class="mt-3 mx-3"><b>Hussien Talal</b></p>
            <p class="text-sm">Frontend Software Engineer</p>
            <div class="inline-block">
              <a
                class="cursor-pointer hover:text-blue-700 m-1"
                href="mailto:hossientalal2@gmail.com"
              >
                <font-awesome-icon icon="envelope"></font-awesome-icon>
              </a>
            </div>
          </div>


          <div class="p-3 m-3 rounded-lg flex flex-col items-center">
            <img
              src="../assets/mohammed.png"
              alt="profile-pic"
              class="h-32 object-contain rounded-full"
            />
            <p class="mt-3 mx-3"><b>Mohammed Wisam</b></p>
            <p class="text-sm">Frontend Software Engineer</p>
            <div class="inline-block">
              <a
                class="cursor-pointer hover:text-blue-700 m-1"
                href="mailto:redcodemohammed@gmail.com"
              >
                <font-awesome-icon icon="envelope"></font-awesome-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 p-3 md:p-5 bg-blue-100" id="projects">
      <p class="col-span-2 text-xl text-center m-1 md:m-3">
        <b>Our Projects</b>
      </p>

      <div
        class="col-span-2 flex flex-col md:flex-row justify-cetner items-center bg-white p-3 rounded-lg m-3 md:mx-8"
      >
        <div class="p-3 self-center m-1 md:m-3">
          <img
            src="../assets/erp.jpg"
            alt=""
            class="align-self-center object-contain"
          />
          <a
            class="text-xs text-gray-300 justify-self-end"
            href="https://www.freepik.com"
          >
            Designed by upklyak / Freepik
          </a>
        </div>
        <div>
          <p class="text-xl text-center m-1 md:m-3">
            <b>Entrprise Resource Planning</b>
          </p>
          <p class="m-1 md:m-3 text-gray-700">
            Our team has been working tirelessly on building an Entrprise
            Resoucre Planning (ERP) system. Through research, we have found that
            the Iraqi market lacks reliable ERP systems. We have noticed that
            many of the ERP systems in Iraq use outdated technologies with
            incredibly complex user interfaces and user experience. Furthermore,
            the local ERP systems lack critical modern features like seamless
            cloud integration and advanced synchronyzation. We thus believe that
            building a reliable ERP system with an easy-to-use user interface, a
            flexible user experience, and incredibly powerful core features will
            help lcoal businesses tremendously grow their sales and a have a
            smoother way to manage thier businesses.
          </p>
        </div>
      </div>

      <div
        class="col-span-2 flex flex-col md:flex-row justify-cetner items-center bg-white p-3 rounded-lg m-3 md:mx-8"
      >
        <div>
          <p class="text-xl text-center m-1 md:m-3">
            <b>Aleef Time</b>
          </p>
          <p class="m-1 md:m-3 text-gray-700">
            Aleef Time is the all-in-one iOS app designed to enhance your pet parenting experience. 
            Seamlessly manage your pets' health, connect with veterinarians, and explore 
            a vibrant pet-loving community. With Aleef Time, you'll have everything you 
            need to ensure the well-being of your furry friends at your fingertips. 
            Learn more at <a href="https://aleeftime.com/">aleeftime.com</a>.
          </p>
        </div>

        <div class="p-3 self-center m-1 md:m-3">
          <img
            src="../assets/aleef.png"
            alt=""
            class="align-self-center object-contain"
          />
        </div>
      </div>
    </div>

    <div class="p-3 md:p-5 bg-green-100" id="contact">
      <p class="col-span-2 text-xl text-center m-1 md:m-3 mb-5">
        <b>Contact Us</b>
      </p>
      <div
        class="flex flex-col md:flex-row justify-cetner items-center bg-white p-3 rounded-lg m-3 md:my-8"
      >
        <div class="p-3 self-center m-1 md:m-3 md:w-6/12">
          <img
            src="../assets/contact.png"
            alt=""
            class="align-self-center object-cotain"
          />
          <a
            class="text-xs text-gray-300 justify-self-end"
            href="https://www.freepik.com"
            >Designed by stories / Freepik</a
          >
        </div>
        <div class="md:w-6/12">
          <p class="text-lg m-1 md:m-3">
            We look forward to chatting with you
          </p>
          <p class="text-lg m-1 md:m-3">
            <b>Company Address:</b><br />
            near Yarmouk gas station, Yarmouk, Baghdad, Iraq
          </p>
          <p class="text-lg m-1 md:m-3">
            <b>Email Address:</b><br />
            <a href="mailto:info@goclassyllc.com">info@goclassyllc.com</a>
          </p>

          <p class="text-lg m-1 md:m-3">
            <b>Phone Number:</b><br />
            <a href="tel:+964 785 000 7050">+964 785 000 7050</a>
          </p>
        </div>
      </div>
    </div>

    <footer
      class="w-full flex flex-col md:flex-row justify-between bg-gray-900 text-gray-400 text-center p-3"
    >
      <div class="text-sm m-2">
        Classy LLC. © - All rights reserved. 2023
      </div>
      <div class="text-sm m-2">
        Credits: Icons made by
        <a href="https://www.flaticon.com/authors/srip" title="srip">srip</a>,
        <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp"
          >Eucalyp</a
        >
        and
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik"
          >Freepik</a
        >
        from
        <a href="https://www.flaticon.com/" title="Flaticon"
          >www.flaticon.com</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped lang="postcss">
.slider {
  background: url("../assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 90vh;
}
</style>
